jQuery(document).ready(function($){

    // console.log(wp);
    if($("input.search-input").length) {
        if($("input.search-input").val() != "") {
            $('.search-submit').show("fast");
        }
        $('input.search-input').keyup(function() {
            if($(this).val() != "") {
                $('.search-submit').show("fast");
            } else {
                $('.search-submit').hide("fast");
            }
        });
    }
});