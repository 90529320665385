if (typeof FakeSiteBuilder !== 'undefined') {
    let totalLayouts = 0;

    function initWpEditor() {
        // Store the content of existing editors
        var editorContent = {};

        // Iterate over all elements with class 'acf-wysiwyg-wrap'
        $('.acf-wysiwyg-wrap').each(function () {
            var containerId = $(this).attr('id');

            wp.editor.remove(containerId);
            // Reinitialize wp_editor for this container
            var settings = {
                textarea_name: containerId,
                media_buttons: true,
                quicktags: true
            };
            wp.editor.initialize(containerId, {
                tinymce: {
                    wpautop: true,
                    // plugins: 'charmap colorpicker hr lists paste tabfocus textcolor fullscreen wordpress wpautoresize wpeditimage wpemoji wpgallery wplink wptextpattern',
                    // toolbar1: 'formatselect,bold,italic,bullist,numlist,blockquote,alignleft,aligncenter,alignright,link,wp_more,spellchecker,fullscreen,wp_adv,listbuttons',
                    // toolbar2: 'styleselect,strikethrough,hr,forecolor,pastetext,removeformat,charmap,outdent,indent,undo,redo,wp_help',
                    // textarea_rows: 20
                },
                quicktags: {
                    buttons: 'strong,em,link,block,del,ins,img,ul,ol,li,code,more,close'
                },
                mediaButtons: false,
            });
            tinymce.get(containerId).on("change keyup", function () {

                const ids = containerId.split('-');
                // [0] - fieldName
                // [1] - sectionId
                const dataType = ids[0];
                const dataSectionId = ids[1];

                let customWYSIWYGContent = tinymce.get(containerId).getContent();

                var $selector = `.${dataType}`;
                $(`.your-website #${dataSectionId} ${$selector}`).html(customWYSIWYGContent);
            });

        });

        // 20250218 - adjust wp-inludes/class-wp-editor.php
        // console.log('test ready');
        $('h1#link-modal-title').each(function(){
            // console.log('test exist');
            $(this).replaceWith( "<h2 id='link-modal-title'>" + $(this).html() + "</h2>" );
        });
    }
    jQuery(document).ready(function ($) {
        // Make elements sortable
        $('.ui-sortable').sortable({
            handle: '.ui-sortable-handle-acf_field',
            stop: function (event, ui) {
                initWpEditor();
                $('.values.ui-sortable').children('.layout.ui-sortable-handle').each(function (index) {
                    var layoutOrderSpan = $(this).find('.acf-fc-layout-order');
                    if (layoutOrderSpan.length > 0) {
                        layoutOrderSpan.text(index + 1);
                    }
                });


                var sortedSectionIds = $('.values.ui-sortable').children('.layout.ui-sortable-handle').map(function () {
                    return $(this).data('sectionid');
                }).get();

                var $yourWebsiteSections = $('.your-website').find('section');
                sortedSectionIds.forEach(function (sectionId, index) {
                    var $section = $yourWebsiteSections.filter('[id="' + sectionId + '"]');
                    if ($section.length) {
                        $('.your-website').append($section);
                    }
                });
            }
        });

        $(document).on('click', '.acf-icon.-minus', function (e) {
            e.preventDefault();

            var sectionId = $(this).data('sectiontoremove');
            if (sectionId !== undefined) {
                $(this).closest('.layout').remove();

                $('.your-website').find('section#' + sectionId).remove();

                let newLayoutsSize = 0;

                $('.values.ui-sortable').children('.layout.ui-sortable-handle').each(function (index) {
                    var layoutOrderSpan = $(this).find('.acf-fc-layout-order');
                    if (layoutOrderSpan.length > 0) {
                        layoutOrderSpan.text(index + 1);
                    }
                    newLayoutsSize = layoutOrderSpan.length;
                });
                totalLayouts = newLayoutsSize;
            }
        });


        $(document.body).on('click', '.layout.ui-sortable-handle .ui-sortable-handle-acf_field', function () {
            if ($(this).parent().hasClass('-collapsed')) {
                $(this).parent().find('.acf-fields').show();
                $(this).parent().removeClass('-collapsed');
            } else {
                $(this).parent().addClass('-collapsed');
                $(this).parent().find('.acf-fields').hide();
            }
        });

        $(document.body).on('click', '.layout.ui-sortable-handle a.acf-icon.-collapse', function (e) {
            e.preventDefault();
            if ($(this).parent().parent().hasClass('-collapsed')) {
                $(this).parent().parent().find('.acf-fields').show();
                $(this).parent().parent().removeClass('-collapsed');
            } else {
                $(this).parent().parent().addClass('-collapsed');
                $(this).parent().parent().find('.acf-fields').hide();
            }
        });

    });

    // more complex js
    (function ($) {
        $(document).ready(function () {

            const sections = [{
                section: 'heroBanner',
                sectionTitleClean: 'Hero Banner',
                sectionFields: [{
                    label: 'title',
                    fieldName: 'title',
                    type: 'text'
                },
                {
                    label: 'copy',
                    fieldName: 'copy',
                    type: 'wysiwyg'
                },
                {
                    label: 'button',
                    fieldName: 'link',
                    type: 'link'
                },
                {
                    label: 'image',
                    fieldName: 'image',
                    type: 'image'
                }
                ]
            }, {
                section: 'cardsRepeater',
                sectionTitleClean: 'Cards Repeater',
                sectionFields: [{
                    label: 'title',
                    fieldName: 'title',
                    type: 'text'
                },
                {
                    label: 'copy',
                    fieldName: 'copy',
                    type: 'wysiwyg'
                },
                {
                    label: 'cardsRepeater',
                    fieldName: 'cardsRepeater',
                    type: 'repeater'
                },
                {
                    label: 'backgroundColor',
                    fieldName: 'backgroundColor',
                    type: 'button_group',
                    options: ['white', 'lightGreen', 'lightPurple', 'light', 'dark']
                }
                ]
            }, {
                section: 'imageContent',
                sectionTitleClean: 'Image Content',
                sectionFields: [{
                    label: 'title',
                    fieldName: 'title',
                    type: 'text'
                },
                {
                    label: 'copy',
                    fieldName: 'copy',
                    type: 'wysiwyg'
                },
                {
                    label: 'button',
                    fieldName: 'link',
                    type: 'link'
                },
                {
                    label: 'image',
                    fieldName: 'image',
                    type: 'image'
                },
                {
                    label: 'textSide',
                    fieldName: 'textSide',
                    type: 'button_group',
                    options: ['first', 'second']
                },
                {
                    label: 'layout',
                    fieldName: 'layout',
                    type: 'button_group',
                    options: ['stacked', 'horizontal']
                },
                {
                    label: 'backgroundColor',
                    fieldName: 'backgroundColor',
                    type: 'button_group',
                    options: ['white', 'lightGreen', 'lightPurple', 'light', 'dark']
                }
                ]
            }];

            sections.forEach((section) => {
                $('.acf-tooltip.acf-fc-popup ul').append(`<li><a href="#" class="add-data-layout-btn" data-layout="${section.section}" data-min="" data-max="">${section.sectionTitleClean}</a></li>`);
            })

            $(document).on('click', function (e) {
                if (!$(e.target).closest('#add_section_layout').length) {
                    $('.acf-tooltip.acf-fc-popup').hide();
                } else {
                    $('.acf-tooltip.acf-fc-popup').show();
                }
            });

            $('#add_section_layout').click(function (e) {
                e.preventDefault();
                $('.acf-tooltip.acf-fc-popup').show();
            });

            /* start fake markup for ACF field generator functions */
            const textField = (id, type, label, layoutFields) => {
                let defaultValue = '';
                if (layoutFields) {
                    defaultValue = layoutFields[type] ?? '';
                }
                return (`
                        <div class="acf-field acf-field-text">
                            <div class="acf-label">
                                <label>${label}</label>
                            </div>
                            <div class="acf-input">
                                <div class="acf-input-wrap">
                                    <input class="editable-acf-input" data-sectionId="${id}" data-type="${type}" type="text" value="${defaultValue}">
                                </div>
                            </div>
                        </div>
                    `)
            };

            const buttonField = (id, type, label) => `
        <div class="acf-field acf-field-link">
            <div class="acf-label">
                <label>${label}</label>
            </div>
            <div class="acf-input">
            <div class="acf-link -value">
                    
                    <div class="link-wrap">
                        <span class="link-title">Your Link</span>
                        <a class="link-url" href="#" target="_blank">#</a>
                        <i class="acf-icon -link-ext acf-js-tooltip" title="Opens in a new window/tab"></i><a class="acf-icon -pencil -clear acf-js-tooltip" data-name="edit" href="#" title="Edit"></a><a class="acf-icon -cancel -clear acf-js-tooltip" data-name="remove" href="#" title="Remove"></a>
                    </div>
                    
                </div>
            </div>
        </div>
        `;

            const fixOptionName = str => str.replace(/([A-Z])/g, ' $1').trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

            const buttonGroupField = (id, type, label, options, layoutFields) => {
                return (`
            <div class="acf-field acf-field-button-group">
                <div class="acf-label">
                    <label>${label}</label>
                </div>
                <div class="acf-input">
                    <div class="acf-button-group">
                        ${options.map((option, i) => `<label ${layoutFields[type] === option ? 'class="selected"' : ''}>
                            <input type="radio" value="${option}" data-type="${type}" data-sectionid="${id}" ${layoutFields[type] === option ? 'checked="checked"' : ''}> ${fixOptionName(option)}
                        </label>`).join('')}
                    </div>
                </div>
            </div>
            `);
            };

            const imageField = (id, type, label, layoutFields) => {
                let defaultValue = FakeSiteBuilder.settings.default_image;
                if (layoutFields) {
                    if (layoutFields[type])
                        defaultValue = layoutFields[type] ?? FakeSiteBuilder.settings.default_image;
                }
                return (`
            <div class="acf-field acf-field-image">
                <div class="acf-label">
                    <label>${label}</label>
                    <small>Note: you will be able to select your own images on your website.</small>
                </div>
                <div class="acf-input">
                    <div class="acf-image-uploader has-value" data-preview_size="medium" data-library="all" data-mime_types="" data-uploader="wp">
                        <div class="show-if-value image-wrap" style="max-width: 250px;">
                            <img src="${defaultValue}" alt="" data-name="image">
                        </div>
                    </div>
                </div>
            </div>
            `);
            };

            const repeaterField = (id, type, label, layoutFields) => {
                return (`
                    <div class="acf-field acf-field-repeater">
                        <div class="acf-label">
                            <label>${label}</label>
                        </div>
                        <div class="acf-input">
                            <div class="acf-repeater -row" data-sectionId="${id}" data-type="${type}" data-min="0" data-max="0">
                                <input type="hidden">
                                <table class="acf-table">
                                    <tbody class="ui-sortable-repeater">

                                    </tbody>
                                </table>
                                <div class="acf-actions">
                                    <a class="acf-button button button-primary acf-repeater-add-row" data-sectionId="${id}" href="#" title="Add Card">Add Card</a>
                                </div>
                            </div>
                        </div>
                    </div>
                `);
            };

            const createWysiwygField = (containerId, type, layoutFields) => {
                let defaultValue = '';
                if (layoutFields) {
                    defaultValue = layoutFields[type] ?? '';
                }

                return `
            <div class="acf-field acf-field-wysiwyg">
                <div class="acf-label">
                    <label for="${containerId}">WYSIWYG Editor</label>
                </div>
                <div class="acf-input">
                    <div id="${containerId}" data-type="${type}" class="acf-wysiwyg-wrap">
                        ${defaultValue}
                    </div>
                </div>
            </div>
        `;
            }

            function retrieveSectionByName(sectionName) {
                return sections.find(function (section) {
                    return section.section === sectionName;
                });
            }

            function titleCase(str) {
                str = str.replace(/([a-z])([A-Z])/g, '$1 $2');

                return str.toLowerCase().replace(/(^|\s)\S/g, function (char) {
                    return char.toUpperCase();
                });
            }

            const createACFHandleWrapper = (id, retrievedSection, acfWrapperRetrievedFields, order, layoutOptions) => {
                const {
                    isCollapsed,
                    layoutFields
                } = layoutOptions;

                let renderedFields = acfWrapperRetrievedFields.map((retrievedField) => {
                    const retrievedFieldLabel = titleCase(retrievedField.label);
                    switch (retrievedField.type) {
                        case "text":
                            return textField(id, retrievedField.fieldName, retrievedFieldLabel, layoutFields);
                        case "wysiwyg":
                            return createWysiwygField(retrievedField.fieldName + '-' + id, retrievedField.fieldName, layoutFields);
                        case "link":
                            return buttonField(id, retrievedField.fieldName, retrievedFieldLabel);
                        case "image":
                            return imageField(id, retrievedField.fieldName, retrievedFieldLabel, layoutFields, layoutFields);
                        case "repeater":
                            return repeaterField(id, retrievedField.fieldName, retrievedFieldLabel, layoutFields);
                        case "button_group":
                            return buttonGroupField(id, retrievedField.fieldName, retrievedFieldLabel, retrievedField.options, layoutFields);
                        default:
                            return '';
                    }
                }).join('');

                const collapsedLayout = `
            <div data-sectionId="${id}" class="layout ui-sortable-handle ${layoutOptions.isCollapsed ? '-collapsed' : ''}" data-id="66046326778ad" data-layout="page_title">
                <div class="acf-fc-layout-handle ui-sortable-handle ui-sortable-handle-acf_field" title="Drag to reorder" data-name="collapse-layout"><span class="acf-fc-layout-order">${order}</span> ${retrievedSection.sectionTitleClean}</div>

                <div class="acf-fc-layout-controls">
                    <a class="acf-icon -duplicate small light acf-js-tooltip" href="#" data-name="duplicate-layout" title="Duplicate layout"></a>
                    <a class="acf-icon -minus small light acf-js-tooltip" data-sectiontoremove="${id}" href="#" data-name="remove-layout" title="Remove layout"></a>
                    <a class="acf-icon -collapse small -clear acf-js-tooltip" href="#" data-name="collapse-layout" title="Click to toggle"></a>
                </div>
                <div class="acf-fields">
                    ${renderedFields}
                </div>
            </div>
            `;
                return collapsedLayout;
            }
            /* end fake markup for ACF field generator functions */

            /* start fake markup for sections generator functions */
            /**
             * Hero Banner Section
             */
            const genHeroBannerSection = (id, layoutFields) => {
                /*
                sectionFields: [{
                        label: 'title',
                        type: 'text'
                    },
                    {
                        label: 'copy',
                        type: 'wysiwyg'
                    },
                    {
                        label: 'button',
                        type: 'link'
                    },
                    {
                        label: 'image',
                        type: 'image'
                    }
                ]
                */
                let defaultTitle = '';
                let defaultCopy = '';
                let defaultSectionBackground = FakeSiteBuilder.settings.default_image;
                if (layoutFields !== undefined) {
                    defaultTitle = layoutFields.title ?? '';
                    defaultCopy = layoutFields.copy ?? '';
                    if (layoutFields.image)
                        defaultSectionBackground = layoutFields.image ?? '';
                }
                let heroBannerMarkup = `
                <section class="acf-hero-banner" id="${id}">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="acf-hero-banner-content">
                                    <h2 class="title">${defaultTitle}</h2>
                                    <div class="copy mb-last-0">${defaultCopy}</div>
                                    <a href="#" class="btn btn-secondary">Your Link</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img class="section-background" src="${defaultSectionBackground}" alt="">
                </section>
            `;
                return heroBannerMarkup;
            }
            /**
             * Cards Repeater Section
             */
            const genCardForRepeaterSection = (order, title, copy) => {
                return `
            <div class="col-md-3">
                <div class="card" data-order="${order}">
                    <h3 class="card-title">${title}</h3>
                    <div class="card-copy mb-last-0"><p>${copy}</p></div>
                </div>
            </div>
            `;
            }
            const genCardsRepeaterSection = (id, layoutFields) => {
                /*
                sectionFields: [{
                        label: 'title',
                        fieldName: 'title',
                        type: 'text'
                    },
                    {
                        label: 'copy',
                        fieldName: 'copy',
                        type: 'wysiwyg'
                    },
                    {
                        label: 'cardsRepeater',
                        fieldName: 'cardsRepeater',
                        type: 'repeater'
                    },
                    {
                        label: 'backgroundColor',
                        fieldName: 'backgroundColor',
                        type: 'button_group',
                        options: ['white', 'lightGreen', 'lightPurple', 'light', 'dark']
                    }
                ]
                */
                let defaultTitle = '';
                let defaultCopy = '';
                let defaultCards = '';
                let defaultBackground = 'white';
                if (layoutFields !== undefined) {
                    defaultTitle = layoutFields.title ?? '';
                    defaultCopy = layoutFields.copy ?? '';
                    defaultBackground = layoutFields.backgroundColor ?? 'white';
                    // defaultCards = layoutFields.cards ? layoutFields.cards.map((card, i) => genCardForRepeaterSection(i, card.title ?? '', card.copy ?? '')) : '';
                }
                let cardRepeaterMarkup = `
                <section class="acf-cards-repeater bg-${defaultBackground}" id="${id}">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="acf-hero-banner-content">
                                    <h2 class="title">${defaultTitle}</h2>
                                    <div class="copy mb-last-0">${defaultCopy}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row cards">
                            ${defaultCards}
                        </div>
                    </div>
                </section>
            `;
                return cardRepeaterMarkup;
            }
            /**
             * Image Content Section
             */
            const genImageContentSection = (id, layoutFields) => {
                /*
                sectionFields: [{
                        label: 'title',
                        fieldName: 'title',
                        type: 'text'
                    },
                    {
                        label: 'copy',
                        fieldName: 'copy',
                        type: 'wysiwyg'
                    },
                    {
                        label: 'button',
                        fieldName: 'link',
                        type: 'link'
                    },
                    {
                        label: 'image',
                        fieldName: 'image',
                        type: 'image'
                    },
                    {
                        label: 'textside',
                        fieldName: 'textside',
                        type: 'button_group',
                        options: ['first', 'second']
                    },
                    {
                        label: 'layout',
                        fieldName: 'layout',
                        type: 'button_group',
                        options: ['stacked', 'horizontal']
                    },
                    {
                        label: 'backgroundColor',
                        fieldName: 'backgroundColor',
                        type: 'button_group',
                        options: ['white', 'lightGreen', 'lightPurple', 'light', 'dark']
                    }
                ]
                */
                let defaultTitle = '';
                let defaultCopy = '';
                let defaultImage = FakeSiteBuilder.settings.default_image;

                let defaultBackground = 'white';
                let defaultTextSide = 'first';
                let defaultLayout = 'stacked';
                if (layoutFields !== undefined) {
                    defaultTitle = layoutFields.title ?? '';
                    defaultCopy = layoutFields.copy ?? '';
                    if (layoutFields.image)
                        defaultImage = layoutFields.image ?? '';
                    defaultBackground = layoutFields.backgroundColor;
                    defaultTextSide = layoutFields.textSide;
                    defaultLayout = layoutFields.layout;
                }
                let imageContentMarkup = `
                <section class="acf-image-content-section bg-${defaultBackground} textside-${defaultTextSide} layout-${defaultLayout}" id="${id}">
                    <div class="container">
                        <div class="row">
                            <div data-divid="textimage_formation" data-divtype="text" class="col-md-${defaultLayout === 'horizontal' ? 6 : 12} order-${defaultTextSide === 'first' ? 1 : 2}">
                                <div class="acf-image-content-section-content">
                                    <h2 class="title">${defaultTitle}</h2>
                                    <div class="copy mb-last-0">${defaultCopy}</div>
                                    <a href="#" class="button">Your Link</a>
                                </div>
                            </div>
                            <div data-divid="textimage_formation" data-divtype="image" class="image-col col-md-${defaultLayout === 'horizontal' ? 6 : 12} order-${defaultTextSide === 'first' ? 2 : 1}">
                                <div class="acf-image-content-section-image">
                                    <img class="image" src="${defaultImage}" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            `;
                return imageContentMarkup;
            }
            /* end fake markup for sections generator functions */

            /* start input text field manipulation */
            $(document).on('input', '.editable-acf-input', function () {
                var inputValue = $(this).val();

                let dataSectionId = $(this).data('sectionid');
                let dataType = $(this).data('type');

                var $selector = `.${dataType}`;
                $(`.your-website #${dataSectionId} ${$selector}`).html(inputValue);
            });

            $(document).on('click', '.wp-core-ui', function () {
                if (!$('.acf-field.has-been-initiated').length) {
                    $('.acf-field').addClass('has-been-initiated');

                    if (!$('#toasty_2').hasClass('on')) {
                        $('#toasty_2').toggleClass('on');
                        setTimeout(function () {
                            $('#toasty_2').toggleClass('on').toggleClass('off');
                        }, 3000);
                    }
                    // Toastify({
                    //     text: "You found me! Start typing to see the magic happen.",
                    //     duration: 3000,
                    //     gravity: "top",
                    //     position: "right",
                    //     stopOnFocus: true,
                    //     style: {
                    //         background: "#78c237",
                    //     },
                    // }).showToast();
                }
            })
            /* end input text field manipulation */

            /* start repeater manipulation */
            function updateRepeaterInputs(sectionId) {
                $(`.acf-cards-repeater#${sectionId} .cards`).html('');

                $(`.acf-repeater[data-sectionid='${sectionId}'] .acf-row`).each(function (rowIndex) {
                    var $row = $(this);
                    var sectionId = $row.closest('.acf-repeater').data('sectionid');

                    $row.find('.editable-acf-repeatableinput').each(function (inputIndex) {
                        $(this).attr('data-sectionId', sectionId);
                        $(this).attr('data-orderId', rowIndex + 1);
                    });

                    $row.find('.acf-row-handle.order span').text(rowIndex + 1);

                    let title = $(`.editable-acf-repeatableinput[data-sectionid="${sectionId}"][data-orderid="${rowIndex + 1}"][data-editing="card-title"]`).val();
                    let copy = $(`.editable-acf-repeatableinput[data-sectionid="${sectionId}"][data-orderid="${rowIndex + 1}"][data-editing="card-copy"]`).val();

                    $(`.acf-cards-repeater#${sectionId} .cards`).append(genCardForRepeaterSection(rowIndex + 1, title, copy));
                });

                $(".editable-acf-repeatableinput").off();
                // Had to rewrite this in plain js due to some weird jquery ref error
                document.addEventListener('input', function (event) {
                    if (event.target.classList.contains('editable-acf-repeatableinput')) {
                        let inputValue = event.target.value;
                        let dataSectionId = event.target.dataset.sectionid;
                        let dataType = event.target.dataset.editing;
                        let order = event.target.dataset.orderid;
                        let selector = '.' + dataType;
                        let targetElement = document.querySelector('.your-website #' + dataSectionId + ' .cards .card[data-order="' + order + '"] ' + selector);

                        if (targetElement) {
                            targetElement.innerHTML = inputValue;
                        }
                    }
                });
            }

            function generateRepeaterCardRow(e, sectionId, data) {
                if (e)
                    e.preventDefault();
                // let $repeater = $(this).closest('.acf-repeater');
                let defaultTitle = '';
                let defaultCopy = '';
                if (data !== undefined) {
                    defaultTitle = data.title;
                    defaultCopy = data.copy;
                }
                let $repeater = $(`.acf-repeater[data-sectionid='${sectionId}']`);
                let template = `
                <tr class="acf-row" style="">
                    <td class="acf-row-handle order ui-sortable-handle" title="Drag to reorder">
                        <span>1</span>
                    </td>
                    <td class="acf-fields -left">
                        <div class="acf-field acf-field-text -collapsed-target" data-name="title" data-type="text">
                            <div class="acf-label">
                                <label>Card Title</label>
                            </div>
                            <div class="acf-input">
                                <div class="acf-input-wrap">
                                    <input type="text" data-sectionId="${sectionId}" data-orderId="${$repeater.find('.acf-row').length}" data-editing="card-title" class="editable-acf-repeatableinput" value="${defaultTitle}">
                                </div>
                            </div>
                        </div>
                        <div class="acf-field acf-field-text" data-name="copy" data-type="text">
                            <div class="acf-label">
                                <label>Card Copy</label>
                            </div>
                            <div class="acf-input">
                                <div class="acf-input-wrap">
                                    <input type="text" data-sectionId="${sectionId}" data-orderId="${$repeater.find('.acf-row').length}" data-editing="card-copy" class="editable-acf-repeatableinput" value="${defaultCopy}">
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="acf-row-handle remove">
                        <a class="acf-icon -minus small acf-repeater-remove-row" data-sectionId="${sectionId}" href="#" title="Remove card"></a>
                    </td>
                </tr>
            `;

                $repeater.find('.ui-sortable-repeater').append(template);

                updateRepeaterInputs(sectionId);
            }

            $(document).on('click', '.acf-repeater-add-row', function (e) {
                generateRepeaterCardRow(e, $(this).data('sectionid'));
            });

            $(document).on('click', '.acf-repeater-remove-row', function (e) {
                e.preventDefault();
                $(this).closest('.acf-row').remove();

                updateRepeaterInputs($(this).data('sectionid'));
            });
            /* end repeater manipulation */

            /* start button group manipulation */
            $(document).on('click', '.acf-button-group label', function (e) {
                $(this).closest('.acf-button-group').find('label').removeClass('selected');

                $(this).addClass('selected');

                let $radio = $(this).find('input[type="radio"]');

                let type = $radio.data('type');
                let sectionId = $radio.data('sectionid');

                let $thisSection = $(`.your-website #${sectionId}`);

                switch (type) {
                    case 'backgroundColor':
                        $thisSection.removeClass(function (index, className) {
                            return (className.match(/\bbg-\S+/g) || []).join(' ');
                        });
                        $thisSection.addClass(`bg-${$radio.val()}`);
                        break;
                    case 'themeStyle':
                        //col-site
                        if ($radio.val() === 'site') {
                            $('.col-section_edit').hide();
                            $('#site-builder-container').removeClass('container');
                            $('#site-builder-container').addClass('container-fluid');
                            $('.col-site').removeClass('col-lg-6').addClass('col-lg-12');
                            calculateRelativeZoom();
                        } else if ($radio.val() === 'both') {
                            $('.col-section_edit').show();
                            $('#site-builder-container').addClass('container');
                            $('#site-builder-container').removeClass('container-fluid');
                            $('.col-site').removeClass('col-lg-12').addClass('col-lg-6');
                            calculateRelativeZoom();
                        }
                        break;
                    case 'textSide':
                        if ($radio.val() === 'second') {
                            $thisSection.find("[data-divtype='text']").removeClass(function (index, className) {
                                return (className.match(/\border-\S+/g) || []).join(' ');
                            });
                            $thisSection.find("[data-divtype='image']").removeClass(function (index, className) {
                                return (className.match(/\border-\S+/g) || []).join(' ');
                            });
                            $thisSection.find("[data-divtype='text']").addClass('order-2');
                            $thisSection.find("[data-divtype='image']").addClass('order-1');
                        } else if ($radio.val() === 'first') {
                            $thisSection.find("[data-divtype='text']").removeClass(function (index, className) {
                                return (className.match(/\border-\S+/g) || []).join(' ');
                            });
                            $thisSection.find("[data-divtype='image']").removeClass(function (index, className) {
                                return (className.match(/\border-\S+/g) || []).join(' ');
                            });
                            $thisSection.find("[data-divtype='text']").addClass('order-1');
                            $thisSection.find("[data-divtype='image']").addClass('order-2');
                        }
                        $thisSection.removeClass(function (index, className) {
                            return (className.match(/\btextside-\S+/g) || []).join(' ');
                        });
                        $thisSection.addClass(`textside-${$radio.val()}`);
                        break;
                    case 'layout':
                        if ($radio.val() === 'horizontal') {
                            $thisSection.find("[data-divid='textimage_formation']").removeClass('col-md-12').addClass('col-md-6');
                        } else if ($radio.val() === 'stacked') {
                            $thisSection.find("[data-divid='textimage_formation']").removeClass('col-md-6').addClass('col-md-12');
                        }
                        $thisSection.removeClass(function (index, className) {
                            return (className.match(/\blayout-\S+/g) || []).join(' ');
                        });
                        $thisSection.addClass(`layout-${$radio.val()}`);
                        break;
                }

                $(this).find('input[type="radio"]').prop('checked', true);
            });
            /* end button group manipulation */

            function addNewLayout(selectedLayout, props) {
                const {
                    isCollapsed,
                    layoutFields
                } = props;

                const retrievedSection = retrieveSectionByName(selectedLayout);
                const retrievedSectionFields = retrievedSection.sectionFields;

                totalLayouts++;

                const timestampAsId = "ID_" + new Date().getTime().toString();

                const layoutOptions = {
                    isCollapsed: isCollapsed ?? false,
                    layoutFields: layoutFields
                }

                $('#acf_sections_container').append(createACFHandleWrapper(timestampAsId, retrievedSection, retrievedSectionFields, totalLayouts, layoutOptions));

                switch (selectedLayout) {
                    case "heroBanner":
                        $('.your-website').append(genHeroBannerSection(timestampAsId, layoutFields));
                        break;
                    case "cardsRepeater":
                        $('.your-website').append(genCardsRepeaterSection(timestampAsId, layoutFields));
                        if (layoutFields.cards) {
                            layoutFields.cards.forEach((card, i) => generateRepeaterCardRow(false, timestampAsId, card));
                        }
                        break;
                    case "imageContent":
                        $('.your-website').append(genImageContentSection(timestampAsId, layoutFields));
                        break;
                    default:
                        break;
                }

                if (selectedLayout === 'cardsRepeater') {
                    $(`.acf-repeater[data-sectionid='${timestampAsId}'] .ui-sortable-repeater`).sortable({
                        stop: function (event, ui) {
                            updateRepeaterInputs(timestampAsId);
                        }
                    });
                }
                initWpEditor();
            }

            /*
    
    
            */
            var site_buiilder = $("#site-builder");
            var sectionHeightTitle = $('.section-title').outerHeight(true);
            $('.toastify-container').css('padding-top', `${sectionHeightTitle}px`);

            $(window).on("scroll", function () {
                var windowHeight = $(window).height();
                var windowTopPosition = $(window).scrollTop();
                var windowBottomPosition = (windowTopPosition + windowHeight);

                $.each(site_buiilder, function () {
                    var site_buiilder_window = $(this);
                    var site_buiilder_windowTopPosition = site_buiilder_window.offset().top;
                    var site_buiilder_windowBottomPosition = (site_buiilder_windowTopPosition + site_buiilder_window.outerHeight());

                    if ((site_buiilder_windowBottomPosition >= windowTopPosition) && (site_buiilder_windowTopPosition <= windowBottomPosition) && (!site_buiilder_window.data("visible"))) {
                        site_buiilder.data("visible", "true");
                        setTimeout(function () {
                            // Toastify({
                            //     text: "Looks like you've discovered the magic, start editing the flashing section!",
                            //     duration: 8000,
                            //     gravity: "top",
                            //     position: "right",
                            //     stopOnFocus: true,
                            //     style: {
                            //         background: "#78c237",
                            //     },
                            // }).showToast();
                            if (!$('#toasty_1').hasClass('on')) {
                                var sectionHeightTitle = $('.section-title').outerHeight();
                                $('.toastify-container').css('top', `${sectionHeightTitle}px;`);
                                $('#toasty_1').toggleClass('on');
                                setTimeout(function () {
                                    $('#toasty_1').toggleClass('on').toggleClass('off');
                                }, 5000);
                            }
                        }, 2000);
                    }
                });
            });

            $('.add-data-layout-btn').click(function (e) {
                e.preventDefault();
                const selectedLayout = $(this).data('layout');
                addNewLayout(selectedLayout, {
                    isCollapsed: false,
                    layoutFields: {
                        backgroundColor: 'white',
                        layout: 'horizontal',
                        textSide: 'first'
                    }
                });

                $('.acf-field').addClass('has-been-initiated');
            });

            if (FakeSiteBuilder.settings.default_rendered_sections.length > 0) {
                FakeSiteBuilder.settings.default_rendered_sections.forEach((default_rendered_section, i) => {
                    addNewLayout(default_rendered_section.acf_fc_layout, {
                        isCollapsed: i === 0 ? false : true,
                        layoutFields: default_rendered_section
                    });
                });
            }

            $(document).on('click', '.your-website a', function (e) {
                e.preventDefault();
                if (!$('#toasty_click_custom_link').hasClass('on')) {
                    $('#toasty_click_custom_link').toggleClass('on');
                    setTimeout(function () {
                        $('#toasty_click_custom_link').toggleClass('on').toggleClass('off');
                    }, 3000);
                }
                // Toastify({
                //     text: "Oh, you clicked on one of our custom links!",
                //     duration: 3000,
                //     gravity: "top",
                //     position: "right",
                //     stopOnFocus: true,
                //     style: {
                //         background: "#78c237",
                //     },
                // }).showToast();
            });

            $(document).on('click', '.wp-core-ui .acf-input a.acf-icon.-pencil, .wp-core-ui .acf-input a.acf-icon.-cancel, .wp-core-ui .acf-input a.acf-icon.-duplicate', function (e) {
                e.preventDefault();
                if (!$('#toasty_backend_only').hasClass('on')) {
                    $('#toasty_backend_only').toggleClass('on');
                    setTimeout(function () {
                        $('#toasty_backend_only').toggleClass('on').toggleClass('off');
                    }, 3000);
                }
                // Toastify({
                //     text: "This feature is only available from the backend!",
                //     duration: 3000,
                //     gravity: "top",
                //     position: "right",
                //     stopOnFocus: true,
                //     style: {
                //         background: "#c2373c",
                //     },
                // }).showToast();
            });

            $(document).on('click', '.acf-field-link .link-url', function (e) {
                e.preventDefault();
            })

            var resizeTimeout;

            function calculateRelativeZoom() {
                var targetDiv = $("#your-website");
                targetDiv.css("zoom", 1);

                var rect = targetDiv[0].getBoundingClientRect();
                var zoomLevel = rect.width / window.innerWidth;

                targetDiv.css("zoom", zoomLevel);
            }

            function debounce(func, delay) {
                clearTimeout(resizeTimeout);
                resizeTimeout = setTimeout(func, delay);
            }

            $(window).on("resize", function () {
                debounce(calculateRelativeZoom, 200);
            });

            setTimeout(function () {
                calculateRelativeZoom();
            }, 250);

        });
    })(jQuery);
}