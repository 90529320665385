(function ($) {
    $(document).ready(function () {
        const $header = $('header.header');
        function debounce_rsz(func, wait) {
            let timeout;
            return function (...args) {
                const later = () => {
                    clearTimeout(timeout);
                    func.apply(this, args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        }
        function resize() {
            // console.log("txt");
            document.querySelector(':root').style.setProperty('--header-height', `${$header.outerHeight()}px`);
        }

        const debouncedResize = debounce_rsz(resize, 200);

        $(window).on('resize', debouncedResize);

        resize();
    });
})(jQuery);