jQuery( document ).ready( function($){

    const adjust_header = () => {
        let top = $(window).scrollTop();
        if( 0 == top ){
            $( 'header' ).removeClass( 'opaque' );
        }
        else{
            $( 'header' ).addClass( 'opaque')
        }
    }

    $( window ).scroll( adjust_header );
    adjust_header();

});