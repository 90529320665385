$ = jQuery;
jQuery('document').ready(function(){

    $("#main-menu-toggle").click(function(e){
        // console.log( 'Menu Toggle Fires' );
        if ( $('body').hasClass('lighten') ) {
            e.stopPropagation();
            $('.dropdown.show .dropdown-toggle').trigger('click.bs.dropdown');
            return;
        }
        if(!$(this).hasClass("collapsed")){
            $('body').removeClass("darken lighten");
        } else {
            $('body').addClass("darken");
        }
    });
    $(window).resize(function() {
        // console.log( 'Resize Fires' );
        var browser_width = $(this).outerWidth();
        if (browser_width > 992) {
            $('body').removeClass("darken lighten");
        } else {
            if( ! $("#main-menu-toggle").hasClass("collapsed") && ! $('body').hasClass('darken') ) {
                $('body').addClass("darken");
            }
        }
    });

    $(window).scroll(function(){
        // revealInView("h2");
        revealInView(".bg-shadow");
    });
    revealInView("h2");
    revealInView(".bg-shadow");

    if($(window).outerWidth() < 768) {
        $("#footer-nav ul").hide();
        $("#footer-nav h2").append(' <i class="fal fa-plus"></i>');
        $("#footer-nav h2").click(function(){
            $(this).siblings("ul").slideToggle("fast");    
        });
    }

    // $("a").click(function(){
    //     $("html").addClass("animate");
    // });

});

$('.device img.device_mask').on('load', function() {
    adjust_page_title_container_height();
}).each(function(){
    if(this.complete) {
        $(this).trigger("load");
    }
});

$(document).on('show.bs.dropdown', function(e) {
    $("header.header").addClass("open");
    $("html, body").animate({ scrollTop: 0 }, "fast");
    $(e.relatedTarget).next().animate({ scrollTop: 0 }, "fast")
    $("body.darken").addClass("lighten");
    // $('#main-menu-toggle').collapse({
    //     toggle: false
    //   })

    $("body").addClass("noscroll");
});
$(document).on('hide.bs.dropdown', function(e) {
    // var target = $(e.relatedTarget);

    $("header.header").removeClass("open");
    $("body.darken").removeClass("lighten");
    $("body").removeClass("noscroll");
    // $('#main-menu-toggle').collapse({
    //     toggle: true
    //   })
});
$(document).on('click.bs.dropdown', '.nav-title-link, .link-btn', function(e) {
    if ( $('body').hasClass('darken') ) {
        e.stopPropagation();
    }
});

$(window).on('resize', function(){        
    $(".page-title").css("height", "inherit");
    adjust_page_title_container_height();
});


function revealInView(elem) {
    $(elem).each(function(){
        if(!$(this).hasClass("reveal")) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var elemTop = $(this).offset().top;
            var elemBottom = elemTop + $(this).height();
            var isInView = (elemBottom <= docViewBottom) && (elemTop >= docViewTop);
            if(isInView==true) {
                $(this).addClass("reveal");
            }

            if(elem==".bg-shadow") {
                $(this).width($(this).siblings("img").width());
                $(this).height($(this).siblings("img").height());
            }
        }
    });
}

function adjust_page_title_container_height() {
    if($(".page-title").length) {
        var page_title_height = $(".page-title").height();
        var page_title_image_height = $(".page-title .work-preview .device img").height();

        if($(window).innerWidth() >= 1200){
            if(page_title_image_height >= page_title_height) {
                $(".page-title").css("height", page_title_image_height+"px");
            } else {
                $(".page-title").css("height", "inherit");
            }
        }

        if($(window).innerWidth() < 1200 && $(window).innerWidth() >= 768) {
            $(".page-title h1").css("height", page_title_image_height+"px");
        } else {
            $(".page-title h1").css("height", "inherit");
        }
    }
}