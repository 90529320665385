$(document).ready(function(){
    $(".team-nav a").click(function(e){
        e.preventDefault();
        
        $(".team-nav a").removeClass("active");
        $(this).addClass("active");

        var user_attr = $(this).attr("href").replace('#','');
        $(".card").addClass("transparent");
        $(".card[data-attr*='"+user_attr+"']").removeClass("transparent");
        $('.navbar-collapse').collapse('hide');
    });
});