$(document).ready(function(){
    $('.testimonial-slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        adaptiveHeight: true,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            },
            // {
            // breakpoint: 720,
            // settings: {
            //     slidesToShow: 1,
            //     slidesToScroll: 1
            // }
            //}
        ]
    });
});